import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/pmco.png';

const Footer = () => {
    return (
        <footer style={{ background: '#6a11cb', color: '#fff', padding: '40px 0', textAlign: 'center' }}>
            <div className="container" style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
                <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '10px' }} />
                <h4 style={{ color: '#fff', marginBottom: '20px' }}>Sourceflo AI</h4>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <Link to="/" style={{ color: '#fff', textDecoration: 'none', margin: '0 15px' }}>
                        Terms of Service
                    </Link>
                    <Link to="/" style={{ color: '#fff', textDecoration: 'none', margin: '0 15px' }}>
                        Privacy Policy
                    </Link>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <a
                        href="https://www.facebook.com/profile.php?id=61566316237229"
                        style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}
                    >
                        <FacebookIcon />
                    </a>
                    <a href="https://x.com/PMandCoAI" style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}>
                        <TwitterIcon />
                    </a>

                    <a href="https://www.linkedin.com/in/paul-malott/" style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}>
                        <LinkedInIcon />
                    </a>
                </div>
                <p style={{ color: '#fff', fontSize: '14px' }}>©️ 2024 Sourceflo.ai - Empowering Global Procurement</p>
            </div>
        </footer>
    );
};

export default Footer;
