// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// // material-ui
// import { useTheme } from '@material-ui/styles';
// import {
//     Drawer,
//     Fab,
//     FormControl,
//     FormControlLabel,
//     Grid,
//     IconButton,
//     Radio,
//     RadioGroup,
//     Slider,
//     Tooltip,
//     Typography
// } from '@material-ui/core';

// // third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';

// // project imports
// import SubCard from '../../ui-component/cards/SubCard';
// import AnimateButton from '../../ui-component/extended/AnimateButton';
// import { SET_BORDER_RADIUS, SET_FONT_FAMILY } from '../../store/actions'; // THEME_RTL
// import { gridSpacing } from '../../store/constant';

// // assets
// import { IconSettings } from '@tabler/icons';

// // concat 'px'
// function valueText(value) {
//     return `${value}px`;
// }

// //-----------------------|| LIVE CUSTOMIZATION ||-----------------------//

// const Customization = () => {
//     const theme = useTheme();
//     const dispatch = useDispatch();
//     const customization = useSelector((state) => state.customization);

//     // drawer on/off
//     const [open, setOpen] = React.useState(false);
//     const handleToggle = () => {
//         setOpen(!open);
//     };

//     // state - border radius
//     const [borderRadius, setBorderRadius] = React.useState(customization.borderRadius);
//     const handleBorderRadius = (event, newValue) => {
//         setBorderRadius(newValue);
//     };

//     useEffect(() => {
//         dispatch({ type: SET_BORDER_RADIUS, borderRadius: borderRadius });
//     }, [dispatch, borderRadius]);

//     let initialFont;
//     switch (customization.fontFamily) {
//         case `'Inter', sans-serif`:
//             initialFont = 'Inter';
//             break;
//         case `'Poppins', sans-serif`:
//             initialFont = 'Poppins';
//             break;
//         case `'Roboto', sans-serif`:
//         default:
//             initialFont = 'Roboto';
//             break;
//     }

//     // state - font family
//     const [fontFamily, setFontFamily] = React.useState(initialFont);
//     useEffect(() => {
//         let newFont;
//         switch (fontFamily) {
//             case 'Inter':
//                 newFont = `'Inter', sans-serif`;
//                 break;
//             case 'Poppins':
//                 newFont = `'Poppins', sans-serif`;
//                 break;
//             case 'Roboto':
//             default:
//                 newFont = `'Roboto', sans-serif`;
//                 break;
//         }
//         dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont });
//     }, [dispatch, fontFamily]);

//     return (
//         <React.Fragment>
//             {/* toggle button */}

//             <Tooltip title="Live Customize">
//                 <Fab
//                     component="div"
//                     onClick={handleToggle}
//                     size="medium"
//                     variant="string"
//                     color="secondary"
//                     sx={{
//                         bottom: 0,
//                         m: 4,
//                         position: 'fixed',
//                         right: 20,
//                         zIndex: (theme) => theme.zIndex.speedDial,
//                         boxShadow: theme.shadows[8]
//                     }}
//                 >
//                     <AnimateButton type="rotate">
//                         <IconButton color="inherit" size="large" disableRipple>
//                             <IconSettings />
//                         </IconButton>
//                     </AnimateButton>
//                 </Fab>
//             </Tooltip>

//             <Drawer
//                 anchor="right"
//                 onClose={handleToggle}
//                 open={open}
//                 PaperProps={{
//                     sx: {
//                         width: 280
//                     }
//                 }}
//             >
//                 <PerfectScrollbar component="div">
//                     <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
//                         <Grid item xs={12}>
//                             {/* font family */}
//                             <SubCard title="Font Family">
//                                 <FormControl>
//                                     <RadioGroup
//                                         aria-label="font-family"
//                                         value={fontFamily}
//                                         onChange={(e) => setFontFamily(e.target.value)}
//                                         name="row-radio-buttons-group"
//                                     >
//                                         <FormControlLabel
//                                             value="Roboto"
//                                             control={<Radio />}
//                                             label="Roboto"
//                                             sx={{
//                                                 '& .MuiSvgIcon-root': { fontSize: 28 },
//                                                 '& .MuiFormControlLabel-label': { color: 'grey.900' }
//                                             }}
//                                         />
//                                         <FormControlLabel
//                                             value="Poppins"
//                                             control={<Radio />}
//                                             label="Poppins"
//                                             sx={{
//                                                 '& .MuiSvgIcon-root': { fontSize: 28 },
//                                                 '& .MuiFormControlLabel-label': { color: 'grey.900' }
//                                             }}
//                                         />
//                                         <FormControlLabel
//                                             value="Inter"
//                                             control={<Radio />}
//                                             label="Inter"
//                                             sx={{
//                                                 '& .MuiSvgIcon-root': { fontSize: 28 },
//                                                 '& .MuiFormControlLabel-label': { color: 'grey.900' }
//                                             }}
//                                         />
//                                     </RadioGroup>
//                                 </FormControl>
//                             </SubCard>
//                         </Grid>
//                         <Grid item xs={12}>
//                             {/* border radius */}
//                             <SubCard title="Border Radius">
//                                 <Grid item xs={12} container spacing={2} alignItems="center" sx={{ mt: 2.5 }}>
//                                     <Grid item>
//                                         <Typography variant="h6" color="secondary">
//                                             4px
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item xs>
//                                         <Slider
//                                             size="small"
//                                             value={borderRadius}
//                                             onChange={handleBorderRadius}
//                                             getAriaValueText={valueText}
//                                             valueLabelDisplay="on"
//                                             aria-labelledby="discrete-slider-small-steps"
//                                             marks
//                                             step={2}
//                                             min={4}
//                                             max={24}
//                                             color="secondary"
//                                             sx={{
//                                                 '& .MuiSlider-valueLabel': {
//                                                     color: 'secondary.light'
//                                                 }
//                                             }}
//                                         />
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant="h6" color="secondary">
//                                             24px
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </SubCard>
//                         </Grid>
//                     </Grid>
//                 </PerfectScrollbar>
//             </Drawer>
//         </React.Fragment>
//     );
// };

// export default Customization;
import { Box, Button, Fab, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close, MoreVert } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';

const ChatbotPopup = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [conversationEnded, setConversationEnded] = useState(false);

    const questions = [
        {
            question: 'Have you prepared the RFQ document?',
            options: ['Yes', 'No'],
            next: [1, 2]
        },
        {
            question: 'Have you identified potential suppliers?',
            options: ['Yes', 'No'],
            next: [3, 4]
        },
        {
            question: 'Do you need assistance with the RFQ document?',
            options: ['Yes', 'No'],
            next: [5, 6]
        },
        {
            question: 'Have you sent the RFQ to suppliers?',
            options: ['Yes', 'No'],
            next: [7, 8]
        },
        {
            question: 'Would you like help identifying suppliers?',
            options: ['Yes', 'No'],
            next: [9, 10]
        },
        {
            question: 'Would you like a template for the RFQ?',
            options: ['Yes', 'No'],
            next: [11, 12]
        },
        {
            question: 'Are you ready to proceed with the current document?',
            options: ['Yes', 'No'],
            next: [13, 14]
        },
        {
            question: 'Have you received responses from suppliers?',
            options: ['Yes', 'No'],
            next: [15, 16]
        },
        {
            question: 'Do you need help sending the RFQ?',
            options: ['Yes', 'No'],
            next: [17, 18]
        },
        {
            question: 'Would you like a list of recommended suppliers?',
            options: ['Yes', 'No'],
            next: [19, 20]
        },
        {
            question: 'Are you looking for local or international suppliers?',
            options: ['Local', 'International'],
            next: [21, 22]
        },
        {
            question: 'Would you like assistance with response analysis?',
            options: ['Yes', 'No'],
            next: [23, 24]
        },
        {
            question: 'Do you need to follow up with suppliers?',
            options: ['Yes', 'No'],
            next: [25, 26]
        },
        {
            question: 'Have you finalized the supplier selection?',
            options: ['Yes', 'No'],
            next: [27, 28]
        },
        {
            question: 'Would you like to send reminders to suppliers?',
            options: ['Yes', 'No'],
            next: [29, 30]
        },
        {
            question: 'Do you need help with supplier evaluation?',
            options: ['Yes', 'No'],
            next: [31, 32]
        },
        {
            question: 'Would you like a demo of supplier management tools?',
            options: ['Yes', 'No'],
            next: [33, 34]
        },
        {
            question: 'Would you like to download the template?',
            options: ['Yes', 'No'],
            next: [35, 36]
        },
        {
            question: 'Would you like to revise the document?',
            options: ['Yes', 'No'],
            next: [37, 38]
        },
        {
            question: 'Have you finalized the supplier selection?',
            options: ['Yes', 'No'],
            next: [39, 40]
        },
        {
            question: 'Do you need to negotiate terms with suppliers?',
            options: ['Yes', 'No'],
            next: [41, 42]
        },
        {
            question: 'Would you like a checklist for supplier evaluation?',
            options: ['Yes', 'No'],
            next: [43, 44]
        },
        {
            question: 'Have you set a deadline for supplier responses?',
            options: ['Yes', 'No'],
            next: [45, 46]
        },
        {
            question: 'Do you need to prepare a presentation for management?',
            options: ['Yes', 'No'],
            next: [47, 48]
        },
        {
            question: 'Would you like to automate supplier reminders?',
            options: ['Yes', 'No'],
            next: [49, 50]
        },
        {
            question: 'Do you require legal review of the RFQ?',
            options: ['Yes', 'No'],
            next: [51, 52]
        },
        {
            question: 'Have you conducted a risk assessment for suppliers?',
            options: ['Yes', 'No'],
            next: [53, 54]
        },
        {
            question: 'Would you like to track supplier performance metrics?',
            options: ['Yes', 'No'],
            next: [55, 56]
        },
        {
            question: 'Do you need to update the procurement policy?',
            options: ['Yes', 'No'],
            next: [57, 58]
        },
        {
            question: 'Would you like to integrate with existing procurement systems?',
            options: ['Yes', 'No'],
            next: [59, 60]
        },
        {
            question: 'Have you documented all supplier interactions?',
            options: ['Yes', 'No'],
            next: [61, 62]
        },
        {
            question: 'Do you need to provide training for the procurement team?',
            options: ['Yes', 'No'],
            next: [63, 63]
        }
    ];

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleOptionClick = (answer, nextIndex) => {
        const currentQuestion = questions[currentQuestionIndex];
        setChatHistory([...chatHistory, { sender: 'bot', text: currentQuestion.question }, { sender: 'user', text: answer }]);

        if (nextIndex !== undefined && questions[nextIndex]) {
            setCurrentQuestionIndex(nextIndex);
        } else {
            const contactMessage = 'For further assistance, please contact us at 123-456-7890.';
            setChatHistory((prevHistory) => [...prevHistory, { sender: 'bot', text: contactMessage }]);
            setConversationEnded(true);
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEndChat = () => {
        setChatHistory([]);
        setCurrentQuestionIndex(0);
        setOpen(false);
        setConversationEnded(false);
        handleMenuClose();
    };

    const handleExportTranscription = () => {
        const transcription = chatHistory.map((chat) => `${chat.sender === 'user' ? 'You' : 'Bot'}: ${chat.text}`).join('\n');
        const blob = new Blob([transcription], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'chat_transcription.txt');
        handleMenuClose();
    };

    return (
        <React.Fragment>
            <Fab
                color="secondary"
                onClick={handleToggle}
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    zIndex: 10000, // High z-index
                    boxShadow: theme.shadows[8],
                    bgcolor: '#51e8b8',
                    '&:hover': {
                        bgcolor: '#51e8b8'
                    }
                }}
            >
                <IconButton color="inherit">{open ? <Close /> : <MoreVert />}</IconButton>
            </Fab>

            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 80,
                        right: 20,
                        width: '90%',
                        maxWidth: 350,
                        height: '70%',
                        maxHeight: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 3,
                        borderRadius: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        border: `1px solid ${theme.palette.divider}`,
                        zIndex: 9999, // High z-index
                        '@media (max-width: 600px)': {
                            width: '95%',
                            height: '80%',
                            bottom: 60,
                            right: 10
                        }
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: '#51e8b8',
                            color: theme.palette.primary.contrastText,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px'
                        }}
                    >
                        <Typography variant="h6">Sourceflo Bot</Typography>
                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <MoreVert />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={handleExportTranscription}>Export Transcription</MenuItem>
                            <MenuItem onClick={handleEndChat}>End Chat</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
                        {chatHistory.map((chat, index) => (
                            <Box
                                key={index}
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    justifyContent: chat.sender === 'user' ? 'flex-end' : 'flex-start'
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        bgcolor: chat.sender === 'user' ? theme.palette.primary.light : theme.palette.grey[200],
                                        borderRadius: 1,
                                        maxWidth: '70%'
                                    }}
                                >
                                    <Typography variant="body2">{chat.text}</Typography>
                                </Box>
                            </Box>
                        ))}
                        {!conversationEnded && questions[currentQuestionIndex] && (
                            <Box
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        bgcolor: theme.palette.grey[200],
                                        borderRadius: 1,
                                        maxWidth: '70%',
                                        mb: 1
                                    }}
                                >
                                    <Typography variant="body2">{questions[currentQuestionIndex].question}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1
                                    }}
                                >
                                    {questions[currentQuestionIndex].options.map((option, index) => (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            sx={{ mb: 1 }}
                                            onClick={() => handleOptionClick(option, questions[currentQuestionIndex].next[index])}
                                        >
                                            {option}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};

export default ChatbotPopup;
