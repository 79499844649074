// LandingPage.js
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import herowidget from '../../../assets/images/bars.png';
import logo from '../../../assets/images/pmco.png';
import herowidget1 from '../../../assets/images/speed_meter.png';
import '../../../assets/styles/style.css';
import configData from '../../../config'; // Adjust the path as necessary
import { LOGOUT } from '../../../store/actions';
import Footer from '../footer';

const LandingPage = () => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const dispatcher = useDispatch();

    const handleLogout = () => {
        axios
            .post(configData.API_SERVER + 'users/logout', { token: `${account.token}` }, { headers: { Authorization: `${account.token}` } })
            .then(function (response) {
                dispatcher({ type: LOGOUT });
            })
            .catch(function (error) {
                console.log('error - ', error);
            });
    };

    return (
        <div>
            <header className="header">
                <div className="wrapper header__wrapper">
                    <img src={logo} alt="Sourceflo.ai" width="182" className="header__logo-link" />
                    <nav className="header__nav">
                        <Link to="/dashboard/default" className="header__nav-link link">
                            Dashboard
                        </Link>
                        {isLoggedIn ? (
                            <button onClick={handleLogout} className="header__nav-link link highlight">
                                Logout
                            </button>
                        ) : (
                            <Link to="/login" className="header__nav-link link highlight">
                                Login
                            </Link>
                        )}
                    </nav>
                </div>
            </header>
            <main style={{ paddingTop: '100px' }}>
                <section className="information">
                    <div className="information__gradient">
                        <div className="wrapper information__wrapper">
                            <div className="information__content">
                                <h1 className="information__heading">Coming 2025: Manage Vendors & RFQs Like a Pro for Just $15/month!</h1>
                                <p className="information__subheading">
                                    Streamline supplier management and RFQ processes effortlessly with built-in integrations to tools you
                                    already use—like Zoho, HubSpot, Trello, and more.
                                </p>
                                <p className="information__fine-print" style={{ paddingBottom: '10px' }}>
                                    Track vendors, create RFQs, compare bids, monitor performance metrics, and collaborate—all in one
                                    platform.
                                </p>
                                <div className="information__link-group">
                                    {/* <Link to="/register" className="information__link highlight link">
                                        Start Your Free Trial
                                    </Link>
                                    <Link to="/demo" className="information__link--alt link">
                                        Schedule a Demo
                                    </Link> */}

                                    <Formik
                                        initialValues={{
                                            email: ''
                                        }}
                                        validationSchema={Yup.object({
                                            email: Yup.string().email('Invalid email address').required('Required')
                                        })}
                                        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                                            axios
                                                .post(`${configData.API_SERVER}waitlist`, { email: values.email })
                                                .then((response) => {
                                                    alert('You have been added to the waitlist!');
                                                    resetForm();
                                                    setSubmitting(false);
                                                })
                                                .catch((error) => {
                                                    setErrors({ submit: error.response.data.msg || 'Could not add to waitlist' });
                                                    setSubmitting(false);
                                                });
                                        }}
                                    >
                                        {(formik) => (
                                            <form onSubmit={formik.handleSubmit} className="waitlist-form">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    placeholder="Enter your email for waitlist"
                                                    className="information__link  form-input"
                                                    style={{ width: '120px', maxWidth: '500px' }}
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className="form-error">{formik.errors.email}</div>
                                                ) : null}
                                                <button
                                                    type="submit"
                                                    disabled={formik.isSubmitting}
                                                    className="information__link  form-button header__nav-link link highlight"
                                                >
                                                    Join Waitlist
                                                </button>
                                                {formik.errors.submit && <div className="form-error">{formik.errors.submit}</div>}
                                            </form>
                                        )}
                                    </Formik>

                                    <p className="information__fine-print">
                                        Coming soon you’ll be able to try Sourceflo.ai free for 7 days—no credit card required. Upgrade
                                        anytime to unlock full features.
                                    </p>
                                </div>
                            </div>
                            <div className="information__img-group">
                                <img className="information__hero-widget" src={herowidget} alt="hero-widget" />
                                <img className="information__hero-widget-1" src={herowidget1} alt="hero-widget-1" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features" style={{ background: '#f4f4f8', padding: '40px 0' }}>
                    <div className="wrapper" style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px', textAlign: 'center' }}>
                        <h2 style={{ color: '#333', marginBottom: '30px' }}>Why Choose Sourceflo.ai?</h2>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '20px' }}>
                            {[
                                {
                                    title: 'Vendor Management Made Easy',
                                    text: 'Keep all supplier data organized with automated alerts and performance dashboards.'
                                },
                                {
                                    title: 'Effortless RFQ Management',
                                    text: 'Compare bids, track responses, and get AI-powered recommendations in just a few clicks.'
                                },
                                {
                                    title: 'Integrated Workflows',
                                    text: 'Seamless integrations with Trello, Monday.com, Asana, Zoho, HubSpot, and more.'
                                },
                                {
                                    title: 'Scalable for Your Growth',
                                    text: "Whether you're a small business or scaling fast, Sourceflo.ai grows with you."
                                }
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        flex: '1 1 calc(50% - 20px)',
                                        margin: '10px',
                                        padding: '20px',
                                        background: '#fff',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                    }}
                                >
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                            ))}
                        </div>
                        <h2 style={{ color: '#333', marginTop: '40px', marginBottom: '20px' }}>Simple, Transparent Pricing</h2>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '20px' }}>
                            {[
                                {
                                    plan: 'Freemium Plan',
                                    details: 'Free for basic RFQ submission features. Limited Vendor Database Access. E-mail Support.'
                                },
                                {
                                    plan: 'Starter Plan',
                                    details:
                                        '$15/month for multiple RFQs, basic analytics and ai insights, limited vendor comparison, minimal integrations.'
                                },
                                {
                                    plan: 'Professional Plan',
                                    details:
                                        '$49/month for advanced features like bid comparison, analytics, and integrations. 2 distinct analysts (ai integrations) for market, negotiations context.'
                                },
                                {
                                    plan: 'Business Plan',
                                    details:
                                        'Unlimited RFQ, Advanced Analytics, custom comparative analytics, priority support, procurement 3 distinct analysts (ai integrations) for market, negotiations context, internal driven procurement requirements.'
                                },
                                {
                                    plan: 'Enterprise Plan',
                                    details:
                                        'Custom pricing, dedicated account manager, custom integrations, 24/7 support. Automation of functions external to Sourceflo.ai to streamline entire operations, not just procurement!'
                                }
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        flex: '1 1 calc(33% - 20px)',
                                        margin: '10px',
                                        padding: '20px',
                                        background: '#fff',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                    }}
                                >
                                    <h3>{item.plan}</h3>
                                    <p>{item.details}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default LandingPage;
