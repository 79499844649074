import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import Loadable from '../ui-component/Loadable';
import MainLayout from './../layout/MainLayout';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
const UtilsOpenAI = Loadable(lazy(() => import('../views/utilities/OpenaiLLMs')));
const UtilsPerplexity = Loadable(lazy(() => import('../views/utilities/PerplexityLLMs')));
const UtilsAnthropic = Loadable(lazy(() => import('../views/utilities/AnthropicLLMs')));
const RFQManagement = Loadable(lazy(() => import('../views/utilities/RFQManagement')));
const LandingPage = Loadable(lazy(() => import('../views/pages/landing/LandingPage')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    return (
        <Route
            path={[
                '/dashboard/default',

                // '/utils/util-typography',
                // '/utils/util-color',
                // '/utils/util-shadow',
                '/utils/util-rfq-management',
                // '/icons/tabler-icons',
                // '/icons/material-icons',
                '/llms/openai-llm',
                '/llm/perplexity-llm',
                '/llm/anthropic-llm',

                '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        {/* <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} /> */}
                        <Route path="/utils/util-rfq-management" component={RFQManagement} />
                        {/* <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} /> */}
                        <Route path="/llms/openai-llm" component={UtilsOpenAI} />
                        <Route path="/llm/perplexity-llm" component={UtilsPerplexity} />
                        <Route path="/llm/anthropic-llm" component={UtilsAnthropic} />

                        <Route path="/sample-page" component={SamplePage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
