import React from 'react';

// material-ui
import { useTheme } from '@material-ui/styles';

import logo from './../assets/images/pmco.png';
//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    const theme = useTheme();

    return <img src={logo} alt="PMCO" width="182" />;
};

export default Logo;
