// assets
import { IconBrandFramer, IconLayoutGridAdd, IconPalette, IconShadow, IconTypography, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        // {
        //     id: 'util-typography',
        //     title: 'Typography',
        //     type: 'item',
        //     url: '/utils/util-typography',
        //     icon: icons['IconTypography'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-color',
        //     title: 'Color',
        //     type: 'item',
        //     url: '/utils/util-color',
        //     icon: icons['IconPalette'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: icons['IconShadow'],
        //     breadcrumbs: false
        // },
        {
            id: 'util-rfq-management',
            title: 'RFQ Creation',
            type: 'item',
            url: '/utils/util-rfq-management',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'llms',
            title: 'Sourceflo.AI Insights',
            type: 'collapse',
            icon: icons['IconWindmill'],
            children: [
                {
                    id: 'openai-llm',
                    title: 'Procurement Guidelines',
                    type: 'item',
                    url: '/llms/openai-llm',
                    breadcrumbs: false
                },
                {
                    id: 'perplexity-llm',
                    title: 'Market Insights',
                    type: 'item',
                    url: '/llm/perplexity-llm',
                    breadcrumbs: false
                },
                {
                    id: 'anthropic-llm',
                    title: 'AI strategic leverage',
                    type: 'item',
                    url: '/llm/anthropic-llm',
                    breadcrumbs: false
                }
            ]
        }
    ]
};
